import '../styles/globals.css';
import { AppProps } from 'next/app';
import { ReactNode, useEffect } from 'react';
import { defineCustomElements as ionDefineCustomElements } from '@ionic/core/loader';
import { UIDReset } from 'react-uid';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { GA_TRACKING_ID, pageview } from '../lib/gtag';

// initAuth();

function MyApp({ Component, pageProps }: AppProps): ReactNode {
  const router = useRouter();

  useEffect(() => {
    ionDefineCustomElements(window);
  });

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <UIDReset prefix="uid_">
      <Script
        id="ga-script"
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="ga-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        useEnterprise
        scriptProps={{ async: true }}>
        <Head>
          <link rel="canonical" href={`https://kliggit.com${router.asPath}`} />
          <meta
            content="initial-scale=1.0, width=device-width"
            name="viewport"
          />
          <meta
            name="og:image"
            content="https://kliggit.com/images/share.png"
          />
        </Head>
        <Component {...pageProps} />
      </GoogleReCaptchaProvider>
    </UIDReset>
  );
}

export default MyApp;
